<template>
  <material-chart-card
    :color="chart.color"
    :data="chart.data"
    :options="options"
    :responsive-options="chart.responsiveOptions"
    :title="chart.title"
    :type="chart.type"
  >
    <template #subtitle>
      <div class="font-weight-light text--secondary">
        <span class="orange--text">{{ averTime }}&nbsp;min</span>&nbsp;平均每日
      </div>
    </template>

    <template #actions>
      <v-icon class="mr-1" small> mdi-clock-outline </v-icon>

      <span
        class="text-caption grey--text font-weight-light"
        v-text="chart.time"
      />
    </template>
  </material-chart-card>
</template>

<script>
import Vue from "vue";
Vue.use(require("vue-chartist"));
import "chartist/dist/chartist.min.css";
const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});
export default {
  name: "reacentTimeChart",
  components: {
    materialChartCard: () => import("@/components/chart/MaterialChartCard"),
  },
  computed: {
    averTime() {
      let arr = this.chart.data.series[0];
      let sum = 0;
      arr.forEach((item) => {
        sum += item;
      });
      return Math.round(sum / arr.length);
    },
    options() {
      let high = 0;
      this.chart.data.series.forEach((series) => {
        let max = Math.max(...series);
        if (high < max) {
          high = max;
        }
      });
      high += high / 10;
      return {
        lineSmooth,
        low: 0,
        showArea: true,
        high: high, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        // fullWidth: true,
      };
    },
  },
  data: () => ({
    chart: {
      type: "Line",
      color: "amber lighten-5",
      title: "最近学习时间",
      time: "此数据每日同步一次",
      data: {
        labels: ["今天", "昨天", "前天", "3天前", "4天前", "5天前", "6天前"],
        series: [[30, 10, 0, 10, 28, 24, 30]],
      },
    },
  }),
};
</script>

<style lang="scss" scoped>
/* Use this selector to override the line style on a given series */
::v-deep .ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: #ffcc80 !important;
}
/* This selector overrides the points style on line charts. Points on line charts are actually just very short strokes. This allows you to customize even the point size in CSS */
::v-deep .ct-series-a .ct-point {
  /* Colour of your points */
  stroke: #ffa12c !important;
}
::v-deep .ct-grid {
  stroke: #ffd24b !important;
}
::v-deep .v-card--material__title {
  font-size: 1.15rem !important;
  letter-spacing: 0.15rem !important;
}
::v-deep .ct-label {
  font-size: 0.8rem !important;
  margin-top: 0.1rem;
  color: #e77c00 !important;
}
</style>
